export const AccessHardware = {
  CONTRACTOR_LOCKBOX: 'contractor_lockbox',
  KEYPAD: 'keypad',
  CODEBOX: 'codebox',
  OTHER: null,
};

export const ContractorAccessHardware = [
  AccessHardware.CONTRACTOR_LOCKBOX,
  AccessHardware.KEYPAD,
  AccessHardware.OTHER,
];

export const AccessHardwareOptions = [
  {
    label: 'Lockbox',
    value: AccessHardware.CONTRACTOR_LOCKBOX,
  },
  {
    label: 'Keypad',
    value: AccessHardware.KEYPAD,
  },
  {
    label: 'Smart Device',
    value: AccessHardware.CODEBOX,
  },
  {
    label: 'Other',
    value: AccessHardware.OTHER,
  },
];

export const AccessHardwareInputPlaceholders = {
  [AccessHardware.CONTRACTOR_LOCKBOX]: 'Lockbox',
  [AccessHardware.KEYPAD]: 'Keypad Code',
  [AccessHardware.OTHER]: 'Access Code',
};
