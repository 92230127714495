<template>
  <ElDialog
    class="sd-device-code-dialog d-flex justify-content-center align-items-center"
    :center="true"
    :top="0"
    visible
    :destroy-on-close="true"
    @close="close"
  >
    <div
      slot="title"
      class="mt-2"
    >
      <h2>
        Get your device code
      </h2>
    </div>
    <div
      v-if="isStageOne"
      class="sd-device-code-dialog-stage-1 sd-dialog-stage d-flex flex-column"
    >
      <h3>Please select a date to issue your code</h3>
      <ElDatePicker
        v-model="dayOfCode"
        type="date"
        align="center"
        :default-value="new Date()"
        :picker-options="{
          disabledDate
        }"
        class="availability-date-picker"
        format="dd MMMM"
        placeholder="Select a day"
        @change="assignDate"
      />
    </div>
    <div
      v-if="isLoading"
      class="d-flex flex-column justify-content-center  align-items-center mt-5"
    >
      <SdSpinner />
    </div>
    <div
      v-if="!isStageOne && !!deviceCode"
      class="d-flex flex-column sd-dialog-stage  align-items-center font-18"
    >
      <span
        class="font-15"
      >Your acess code to {{ device.provider }}
        <b v-if="device.unit && device.unit.property.short_address"> <br>at {{ device.unit.property.short_address }} </b>
        is:</span>
      <div class="sd-device-code-dialog-code-container m-2 d-flex align-items-center">
        <div class="d-flex justify-content-center">
          {{ deviceCode }}
        </div>
        <div class="sd-device-code-dialog-copy-button-container d-flex justify-content-center align-items-center">
          <SdCopyToClipboard
            :copy="String(deviceCode)"
            class="text-green"
            input-id="unit-phone-line"
            :is-icon="true"
          />
        </div>
      </div>
      <div class="sd-device-code-dialog-step-2-inputs-container d-flex flex-column">
        <h4>Email your code to:</h4>
        <ElForm
          ref="form"
          :model="stepTwoPayload"
          :rules="rulesRef"
          @submit.native.prevent="sendNotifications"
        >
          <ElFormItem
            prop="email"
            class="d-flex"
          >
            <ElInput
              v-model="email"
              placeholder="Email"
              @change="handleEmailInputChange"
            />
          </ElFormItem>
          <h4 class="mt-4">
            Send your code via SMS to:
          </h4>
          <ElFormItem
            prop="phone_number"
            class="d-flex mb-4"
          >
            <ElInput
              v-model="phoneNumber"
              placeholder="Phone number"
              type="phone"
              @change="handlePhoneNumberInputChange"
            />
          </ElFormItem>
        </ElForm>
      </div>
    </div>
    <footer
      v-if="!isLoading"
      class="mt-3 d-flex justify-content-between"
    >
      <ElButton
        size="medium"
        @click="close"
      >
        Cancel
      </ElButton>
      <ElButton
        size="medium"
        type="primary"
        :loading="uiFlags.isSending"
        :disabled="uiFlags.isSending || (isStageOne && !dayOfCode) || (!isStageOne && !email && !phoneNumber)"
        @click="handleActionClick"
      >
        {{ isStageOne ? "Generate Code" : "Send Code" }}
      </ElButton>
    </footer>
  </ElDialog>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import get from 'lodash.get';
import moment from 'moment';
import Regex from '@/constants/Regex';

const regexConstants = Regex;

export default {
  name: 'DeviceCodeDialog',
  components: {},
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { id, serial_number } = props.device;
    const deviceCode = ref(null);
    const isLoading = ref(false);
    const isStageOne = ref(true);
    const dayOfCode = ref(null);
    const email = ref('');
    const phoneNumber = ref('');
    const uiFlags = reactive({
      isSending: false,
    });
    const stepTwoPayload = reactive({
      code: null,
      serialNumber: serial_number,
      email,
      address: get(props.device, 'unit.property.short_address'),
      phone_number: phoneNumber,
    });
    const formRules = {
      phone_number: [
        {
          pattern: regexConstants.phone_number,
          message: 'Please enter a valid phone number',
        },
      ],
      email: [
        {
          type: 'email',
          message: 'Please enter a valid email',
        },
      ],
    };
    const rulesRef = ref(formRules);

    return {
      deviceCode,
      isStageOne,
      isLoading,
      dayOfCode,
      email,
      phoneNumber,
      rulesRef,
      stepTwoPayload,
      uiFlags,
      handleEmailInputChange,
      handlePhoneNumberInputChange,
      sendNotifications,
      close,
      assignDate,
      handleActionClick,
      disabledDate,
    };

    function assignDate(date) {
      dayOfCode.value = date;
    }

    function close(emit) {
      context.emit('close', emit);
    }

    function handleEmailInputChange(emailToSendCode) {
      email.value = emailToSendCode;
    }

    function handlePhoneNumberInputChange(phoneNumberToSendCode) {
      phoneNumber.value = phoneNumberToSendCode;
    }

    async function sendNotifications() {
      try {
        uiFlags.isSending = true;
        stepTwoPayload.code = deviceCode.value;
        stepTwoPayload.access_date = getConvertedDate(dayOfCode.value);
        await context.refs.form.validate();
        await context.root.$store.dispatch('Device/sendDeviceCode', stepTwoPayload);
        showSuccessNotify(context, 'Code was sent');
        close();
      } catch (error) {
        showErrorNotify(context, error.message);
        uiFlags.isSending = false;
      }
    }

    async function generateCode() {
      isLoading.value = true;
      isStageOne.value = false;
      const date = getConvertedDate(dayOfCode.value);
      try {
        deviceCode.value = await context.root.$store.dispatch('Device/getCodeByDate', { deviceId: id, date });
      } catch (error) {
        isStageOne.value = true;
        showErrorNotify(context, error.message);
      }
      isLoading.value = false;
    }

    function handleActionClick() {
      return isStageOne.value ? generateCode() : sendNotifications();
    }

    function disabledDate(time) {
      return moment().diff(moment(time), 'days') > 1;
    }

    function getConvertedDate(date) {
      const tz = moment.tz.guess();
      return moment(dayOfCode.value)
        .tz(tz)
        .format('MM-DD-YYYY');
    }
  },
};
</script>
<style lang="scss">
.sd-device-code-dialog {
  .el-dialog {
    width: auto;
    height: auto;
    min-width: 22rem;
    min-height: 15rem;

    h2 {
      margin: 0;
      text-align: start;
    }

    .el-date-editor {
      width: 100%;
    }

    .el-dialog__body {
      padding: 0;

      .sd-dialog-stage {
        padding: 2px 24px;
      }
    }

    footer {
      border-top: 1px solid gray-color("light");
      padding: 15px 25px;
    }

    .sd-device-code-dialog-code-container {
      width: 100%;
      height: 4rem;
      border: 1px dashed theme-color("green");
      background-color: rgba(theme-color("green"), 0.1);

      > div:first-child {
        width: 80%;
      }
      .sd-device-code-dialog-copy-button-container {
        height: 100%;
        width: 20%;
        padding-left: 0.75rem;
        border-left: 1px dashed theme-color("green");
      }
    }
    .sd-device-code-dialog-step-2-inputs-container,
    .el-form-item__content,
    footer {
      width: 100%;
    }
  }
}
</style>
