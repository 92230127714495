<template>
  <SdFloatLabel>
    <ElSelect
      v-model="fieldState"
      :loading="!devices"
      :filterable="true"
      class="w-100"
      placeholder="Select Device"
      @change="selectDevice"
    >
      <ElOption
        v-for="(item, index) in devices"
        :key="item.serialNumber"
        :label="`${item.serialNumber} ${item.displayName ? `('${item.displayName}')` : ''} - ${item.provider}`"
        :value="index"
        :disabled="!!item.unavailabilityText"
      >
        {{ item.serialNumber }}
        <strong v-if="item.displayName">("{{ item.displayName }}")</strong>
        <strong> - {{ item.provider }}</strong>
        {{ item.unavailabilityText }}
      </ElOption>
    </ElSelect>
  </SdFloatLabel>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import { DEVICE_STATUS } from '@/constants/Device';
import get from 'lodash.get';

export default {
  name: 'DevicesAutoComplete',
  props: {
    payload: {
      type: Object,
      required: true,
    },
    selectedUnitId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const devices = ref(null);
    const fieldState = ref(null);
    const selectedDeviceId = ref(props.payload.device_id);
    onMounted(async () => {
      const devicesData = await context.root.$store.dispatch(
        'Device/get',
      );
      devices.value = devicesData.map((device) => {
        let unavailabilityText = '';
        const assignedUnitId = get(device, 'unit.id', null);
        const existing = assignedUnitId === props.selectedUnitId;
        const status = device.status;
        const available = status !== DEVICE_STATUS.IN_USE || existing;

        if (!available) {
          const shorterAddress = device.unit.property.short_address.split(',')[0];
          unavailabilityText = ` (Used for showings at ${shorterAddress})`;
        }
        return {
          id: device.id,
          displayName: device.name ?? device.serial_number,
          disabled: !available,
          provider: device.provider,
          serialNumber: device.serial_number,
          unavailabilityText,
          selectedUnitId: get(device, 'unit.id', null),
        };
      }).sort((a, b) => a.displayName.localeCompare(b.displayName));

      if (props.selectedUnitId) {
        fieldState.value = devices.value.findIndex((item) => item.selectedUnitId === props.selectedUnitId);
        if (Number.isInteger(fieldState.value) && fieldState.value !== -1) {
          await selectDevice(fieldState.value);
        }
      }
    });

    return {
      devices,
      fieldState,
      selectDevice,
    };

    function checkProviderChanged(device) {
      const selectedDevice = devices.value.find((device) => device.id === selectedDeviceId.value);
      return device.provider !== selectedDevice.provider;
    }

    async function selectDevice(i) {
      context.emit('set-device', devices.value[i].id);
    }
  },
};
</script>

<style lang="scss">
.property-field {
  .el-autocomplete {
    padding: 0;
  }
}
.property-field + .el-form-item__error {
  top: 80%;
}
</style>
